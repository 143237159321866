import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001'
});

export const calculateMaxMinValues = (players) => {
  return {
    maxGoals: Math.max(...players.map(p => p.goals)),
    maxGamesPlayed: Math.max(...players.map(p => p.gamesPlayed)),
    maxGoalsPerGame: Math.max(...players.map(p => p.goalsPerGame || 0)),
    minGoalsToGo: Math.min(...players.map(p => p.goalsToGo || Infinity)),
    minGamesNeeded: Math.min(...players.map(p => p.gamesNeeded || Infinity))
  };
};

export const processPlayers = (playerList, targetGoals) => {
  return playerList.map(player => ({
    ...player,
    goalsPerGame: player.gamesPlayed > 0 ? player.goals / player.gamesPlayed : 0,
    goalsToGo: Math.max(0, targetGoals - player.goals),
    gamesNeeded: player.gamesPlayed > 0 ? Math.max(0, Math.ceil((targetGoals - player.goals) / (player.goals / player.gamesPlayed))) : Infinity
  }));
};

export const playerColors = {
  ovechkin: '#C8102E',
  gretzky: '#00205B',
  matthews: '#00572D',
  stamkos: '#002868',
  pastrnak: '#FFB81C',
  mcdavid: '#FF4C00',
  rantanen: '#6F263D',
};

export const graphPlayers = ['ovechkin', 'gretzky', 'matthews', 'stamkos', 'pastrnak', 'mcdavid', 'rantanen'];