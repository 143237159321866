import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faStar } from '@fortawesome/free-solid-svg-icons';

const SortArrows = ({ column, sortConfig }) => (
  <span className="ml-1">
    <span className={sortConfig.key === column && sortConfig.direction === 'ascending' ? 'text-blue-300' : 'text-gray-400'}>▲</span>
    <span className={sortConfig.key === column && sortConfig.direction === 'descending' ? 'text-blue-300' : 'text-gray-400'}>▼</span>
  </span>
);

const PlayerTable = ({ players, showImage = true, maxMinValues, sortConfig, sortData, targetGoals }) => {
  const calculateAverage = (key) => {
    const sum = players.reduce((acc, player) => acc + player[key], 0);
    return sum / players.length;
  };

  const averageGames = calculateAverage('gamesPlayed');
  const averageGoalsPerGame = calculateAverage('goals') / averageGames;

  const getHighestValue = (key) => {
    return Math.max(...players.map(player => player[key]));
  };

  const highestGoals = getHighestValue('goals');
  const highestGames = getHighestValue('gamesPlayed');
  const highestGoalsPerGame = Math.max(...players.map(player => player.gamesPlayed > 0 ? player.goals / player.gamesPlayed : 0));
  const lowestGoalsToGo = Math.min(...players.map(player => player.goalsToGo));
  const lowestGamesNeeded = Math.min(...players.map(player => player.gamesNeeded));

  const getBubbleClass = (value, highest, isInverse = false, isGretzky = false) => {
    if (isGretzky) return 'bg-gray-100 text-gray-800';
    if (isInverse) {
      return value === highest ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800';
    }
    return value === highest ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800';
  };

  return (
    <div className="overflow-x-auto">
      <table className={`w-full divide-y divide-gray-200 ${maxMinValues.maxGoals >= targetGoals ? 'opacity-50' : ''}`}> 
        <thead className="bg-blue-600 text-white">
          <tr>
            <th scope="col" className="px-1 sm:px-2 py-1 sm:py-2 text-left text-xs font-medium uppercase tracking-wider">
              Player
            </th>
            <th scope="col" className="px-1 sm:px-2 py-1 sm:py-2 text-center text-xs font-medium uppercase tracking-wider">
              Goals
            </th>
            <th scope="col" className="px-1 sm:px-2 py-1 sm:py-2 text-center text-xs font-medium uppercase tracking-wider">
              Games
            </th>
            <th scope="col" className="px-1 sm:px-2 py-1 sm:py-2 text-center text-xs font-medium uppercase tracking-wider">
              G/Game
            </th>
            <th scope="col" className="px-1 sm:px-2 py-1 sm:py-2 text-center text-xs font-medium uppercase tracking-wider">
              Go left
            </th>
            <th scope="col" className="px-1 sm:px-2 py-1 sm:py-2 text-center text-xs font-medium uppercase tracking-wider">
              Ga Left
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {players.map((player) => {
            const goalsPerGame = player.gamesPlayed > 0 ? player.goals / player.gamesPlayed : 0;
            let textColor = 'text-black';
            const isGretzky = player.name === 'Wayne Gretzky';

            if (player.goals > 0 && player.gamesPlayed > 0) {
              const ratio = player.goals / player.gamesPlayed;
              if (ratio < 0.1) {
                textColor = 'text-blue-800';
              } else if (ratio >= 0.8) {
                textColor = 'text-red-600';
              } else {
                const greenValue = Math.floor(255 - (ratio * 255));
                textColor = `text-rgb(255, ${greenValue}, 0)`;
              }
            }

            return (
              <tr key={player.id} className="hover:bg-blue-50 transition-colors duration-200">
                <td className="px-1 sm:px-2 py-1 sm:py-2 whitespace-nowrap">
                  <div className="flex items-center">
                    {showImage && player.image && (
                      <div className={`relative w-6 h-6 sm:w-8 sm:h-8 mr-1 sm:mr-2 ${isGretzky ? 'gretzky-image' : ''}`}> 
                        <img src={player.image} alt={player.name} className="w-full h-full object-cover rounded-full border-2 border-blue-500 shadow-md" />
                      </div>
                    )}
                    <div>
                      <p className={`text-xs sm:text-sm ${textColor}`}>{player.name}</p>
                      <a href={player.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 text-xs font-medium hidden sm:inline-block">
                        NHL Profile
                      </a>
                    </div>
                  </div>
                </td>
                <td className="px-1 sm:px-2 py-1 sm:py-2 whitespace-nowrap text-center">
                  <span className={`inline-block px-1 sm:px-2 py-1 text-xs rounded-full ${isGretzky ? 'bg-orange-400 text-black' : getBubbleClass(player.goals, highestGoals)}`}>
                    {isGretzky ? <span>894</span> : player.goals}
                  </span>
                </td>
                <td className="px-1 sm:px-2 py-1 sm:py-2 whitespace-nowrap text-center">
                  <span className={`inline-block px-1 sm:px-2 py-1 text-xs rounded-full ${getBubbleClass(player.gamesPlayed, highestGames, false, isGretzky)}`}>
                    {player.gamesPlayed}
                  </span>
                </td>
                <td className="px-1 sm:px-2 py-1 sm:py-2 whitespace-nowrap text-center">
                  <span className={`inline-block px-1 sm:px-2 py-1 text-xs rounded-full ${getBubbleClass(goalsPerGame, highestGoalsPerGame, false, isGretzky)}`}>
                    {goalsPerGame.toFixed(2)}
                  </span>
                </td>
                <td className="px-1 sm:px-2 py-1 sm:py-2 whitespace-nowrap text-center">
                  <span className={`inline-block px-1 sm:px-2 py-1 text-xs rounded-full ${getBubbleClass(player.goalsToGo, lowestGoalsToGo, true, isGretzky)}`}> 
                    {isGretzky ? <FontAwesomeIcon icon={faStar} /> : player.goalsToGo}
                  </span>
                </td>
                <td className="px-1 sm:px-2 py-1 sm:py-2 whitespace-nowrap text-center">
                  <span className={`inline-block px-1 sm:px-2 py-1 text-xs rounded-full ${getBubbleClass(player.gamesNeeded, lowestGamesNeeded, true, isGretzky)}`}> 
                    {isGretzky ? <FontAwesomeIcon icon={faStar} />  : player.gamesNeeded}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerTable;